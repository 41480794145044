
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import BtnRadio from "@/components/scope/btn-radio.vue";
import dayjs from "dayjs";
import echarts from "echarts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const monitor = namespace("monitor");

@Component({
  components: { BtnRadio }
})
export default class Monitor extends Vue {
  @monitor.Action newOverview;
  //import引入的组件需要注入到对象中才能使用
  newOverviewData: any = {};
  abnormalStoreList: any = []
  dateValue: any = '';
  pickerOptions = {
    shortcuts: [{
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  }
  dayjs = dayjs;
  nowDate: any = dayjs();
  freshDate: any = undefined;
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.freshDate = dayjs().format("YYYY-MM-DD");
    this.getData(this.freshDate, this.freshDate)
  }

  getData(startTime?, endTime?) {
    this.newOverview({
      endTime: endTime,
      startTime: startTime
    }).then(repons => {
      let { data } = repons;
      this.newOverviewData = data
      this.abnormalStoreList = data.abnormalStoreList
      let option = {
        color:['#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.data.name + ' : ' + params.data.ratio + '%' + ' (' + params.data.label + ')'
          }
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          data: ['注册用户', '会员用户', '到店用户', '复购用户']
        },
        series: [
          {
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: 'auto',
            height: 'auto',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 14
              }
            },
            data: [
              { value: 80, ratio: 100, name: `注册用户`, label: `${data.allUserCount}人` },
              { value: 60, ratio: data.allMemberProportion, name: `会员用户`, label: `${data.allMemberCount}人` },
              { value: 40, ratio: data.allArrivalUserProportion, name: `到店用户`, label: `${data.allArrivalUserCount}人` },
              { value: 20, ratio: data.allRepurchaseUserProportion, name: `复购用户`, label: `${data.allRepurchaseUserCount}人` }
            ]
          }
        ]
      };
      let option2 = {
        color:['#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc'],
        grid: {
          left: 150
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: [],

        },
        series: [
          {
            type: 'bar',
            data: data,
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ],

      };

      option2.yAxis.data = data.storeServiceSumList.map(item => item.storeName)
      option2.series[0].data = data.storeServiceSumList.map(item => item.serviceCountSum)
      this.chartsInit(option, option2)
    });
  }
  dateChange(val) {
    this.getData(val[0], val[1])
  }
  chartsInit(option, option2) {
    // 基于准备好的dom，初始化echarts实例
    let myChart = echarts.init(this.$refs.funner);
    let stripChart = echarts.init(this.$refs.strip);

    myChart.setOption(option);
    stripChart.setOption(option2);
  }

  // computed: {} //监听属性 类似于data概念
  // watch: {} //监控data中的数据变化
  // mounted() {} //生命周期 - 挂载完成（可以访问DOM元素）
  // beforeCreate() {}, //生命周期 - 创建之前
  // beforeMount() {}, //生命周期 - 挂载之前
  // beforeUpdate() {}, //生命周期 - 更新之前
  // updated() {}, //生命周期 - 更新之后
  // beforeDestroy() {}, //生命周期 - 销毁之前
  // destroyed() {}, //生命周期 - 销毁完成
  // activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
